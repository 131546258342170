<template>
    <BaseUiPopup
        v-model="isOpen"
        :reference="reference"
        :placement="placement"
        :hover="hover"
        :lock-scroll="false"
        render-method="display"
        class="vut-tooltip"
        :offset="4"
        :flip="{
            fallbackPlacements: ['bottom'],
        }"
    >
        <template #arrow>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                :width="svgStyles.width"
                :height="svgStyles.height"
                :viewBox="svgStyles.viewBox"
                class="vut-tooltip__arrow"
            >
                <polygon :points="svgStyles.points" />
            </svg>
        </template>

        <template v-if="!$slots.default">
            {{ label }}
        </template>

        <div class="py-1 px-1" v-else>
            <slot />
        </div>
    </BaseUiPopup>
</template>

<script lang="ts" setup>

import type { BaseUiPopupProps } from '@core/app/components/base/ui/popup/BaseUiPopup.vue'

const {
    reference,
    placement,
    label,
    hover = true,
} = defineProps<{
    reference: BaseUiPopupProps['reference']
    placement?: BaseUiPopupProps['placement']
    label?: string
    hover?: BaseUiPopupProps['hover']
}>()

const isOpen = defineModel<boolean>()

const svgStyles = computed(() => {
    return {
        width: 20,
        height: 10,
        viewBox: '0 0 20 10',
        points: '10,0 20,10 0,10',
    }
})

</script>

<style lang="scss" scoped>

.vut-tooltip {
    background: $vut-c-white;
    box-shadow: $vut-shadow-md;
    padding: 0.25rem 0.75rem;
    border-radius: $vut-border-radius;

    @include vut-popup-transitions;
}

.vut-tooltip__arrow {
    fill: $vut-c-white;
}

</style>
